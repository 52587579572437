import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import menuConfig from '../../config/menuConfig';
import styles from './Layout.module.css';

const Sidebar = () => {
  const [expandedMenus, setExpandedMenus] = useState({});

  // Toggle menu expansion
  const toggleMenu = (menuTitle) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [menuTitle]: !prev[menuTitle]
    }));
  };

  return (
    <div className={styles.sidebar}>
      <ul className={styles.menu}>
        {menuConfig.map((menu, index) => (
          <li key={index} className={styles.parentMenu}>
            <div
              className={styles.menuTitle}
              onClick={() => toggleMenu(menu.title)}
            >
              {menu.title}
              {menu.children.length > 0 && (
                <span className={styles.caret}>
                  {expandedMenus[menu.title] ? '-' : '+'}
                </span>
              )}
            </div>
            {expandedMenus[menu.title] && menu.children.length > 0 && (
              <ul className={styles.subMenu}>
                {menu.children.map((subMenu, subIndex) => (
                  <li key={subIndex}>
                    <NavLink
                      to={subMenu.path}
                      className={({ isActive }) =>
                        isActive ? styles.activeMenuItem : styles.menuItem
                      }
                    >
                      {subMenu.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
