import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getOrganizationById, updateOrganization } from '../../services/organizationStorage';
import { getUsersByRole } from '../../services/userStorage';
import styles from './EditOrganizationPage.module.css';

const EditOrganizationPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    const fetchOrganization = async () => {
      const organization = await getOrganizationById(Number(id));
      if (organization) {
        setFormData(organization);
      } else {
        console.error('Organization not found');
        navigate('/organization/organization-list');
      }
    };
        // Fetch owners
        const fetchOwners = async () => {
          const ownerUsers = await getUsersByRole('owner');
          setOwners(ownerUsers);
        };

    fetchOrganization();
    fetchOwners();
  }, [id, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleOwnerChange = (e) => {
    const selectedId = e.target.value;
    const selectedOwner = owners.find((owner) => String(owner.id) === selectedId);

    if (!selectedOwner) {
      console.error('Selected owner not found in the owners list.');
      return;
    }

    setFormData((prev) => ({
      ...prev,
      ownerName: `${selectedOwner.firstName} ${selectedOwner.lastName}`,
      ownerId: selectedOwner.id,
      phoneNumber: selectedOwner.phoneNumber || '',
      emailId: selectedOwner.email || ''
    }));
  };

  const handleAddressChange = (index, key, value) => {
    const updatedAddresses = formData.addresses.map((address, i) =>
      i === index ? { ...address, [key]: value } : address
    );
    setFormData((prev) => ({ ...prev, addresses: updatedAddresses }));
  };

  const addAddressField = () => {
    setFormData((prev) => ({
      ...prev,
      addresses: [...prev.addresses, { type: 'Communication Address', address: '' }]
    }));
  };

  const removeAddressField = (index) => {
    setFormData((prev) => ({
      ...prev,
      addresses: prev.addresses.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateOrganization(formData);
    navigate('/organization/organization-list');
  };

  if (!formData) return <p>Loading...</p>;

  return (
    <div className={styles.container}>
      <h2>Edit Organization</h2>
      <form onSubmit={handleSubmit}>
        <label>Organization Name</label>
        <input
          type="text"
          name="organizationName"
          value={formData.organizationName}
          onChange={handleInputChange}
          required
        />

        <label>Company Name</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          required
        />

        <label>Owner Name</label>
        <select
          name="ownerId"
          value={formData.ownerId}
          onChange={handleOwnerChange}
          className={styles.select}
          required
        >
          <option value="">Select Owner</option>
          {owners.map((owner) => (
            <option key={owner.id} value={owner.id}>
              {`${owner.firstName} ${owner.lastName}`}
            </option>
          ))}
        </select>

        <label>Owner Mobile No</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          readOnly
          className={styles.readOnlyInput}
        />

        <label>Mail ID</label>
        <input
          type="email"
          name="emailId"
          value={formData.emailId}
          readOnly
          className={styles.readOnlyInput}
        />

        <label>Addresses</label>
        {formData.addresses.map((address, index) => (
          <div key={index} className={styles.addressField}>
            <select
              value={address.type}
              onChange={(e) => handleAddressChange(index, 'type', e.target.value)}
              className={styles.select}
            >
              <option value="Permanent Address">Permanent Address</option>
              <option value="Communication Address">Communication Address</option>
            </select>
            <input
              type="text"
              value={address.address}
              onChange={(e) => handleAddressChange(index, 'address', e.target.value)}
              className={styles.input}
              placeholder="Enter Address"
              required
            />
            {formData.addresses.length > 1 && (
              <button
                type="button"
                onClick={() => removeAddressField(index)}
                className={styles.removeButton}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={addAddressField} className={styles.addButton}>
          + Add Address
        </button>

        <label className={styles.label}>
          <input
            type="checkbox"
            name="deactivated"
            checked={formData.deactivated}
            onChange={(e) => setFormData((prev) => ({ ...prev, deactivated: e.target.checked }))}
          />
          Deactivate
        </label>

        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
          <button
            type="button"
            onClick={() => navigate('/organization/organization-list')}
            className={styles.cancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditOrganizationPage;
