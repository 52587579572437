import { openDB } from 'idb';

// Initialize IndexedDB
const dbPromise = openDB('user-management-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('users')) {
      db.createObjectStore('users', { keyPath: 'id', autoIncrement: true });
    }
  }
});

// Add a user to IndexedDB
export const addUser = async (user) => {
  try {
    const db = await dbPromise;

    // Ensure the username is unique
    const existingUser = await findUserByUsername(user.username);
    if (existingUser) {
      throw new Error(`User with username "${user.username}" already exists.`);
    }

    await db.add('users', user);
    return { success: true };
  } catch (error) {
    console.error('Failed to add user:', error);
    return { success: false, error: error.message };
  }
};

// Get all users from IndexedDB
export const getUsers = async () => {
  try {
    const db = await dbPromise;
    const users = await db.getAll('users');
    return users.map((user) => ({
      ...user,
      name: `${user.firstName || ''} ${user.lastName || ''}`.trim(), // Generate full name
    }));
  } catch (error) {
    console.error('Failed to fetch users:', error);
    return [];
  }
};


// Find a user by username
export const findUserByUsername = async (username) => {
  try {
    const db = await dbPromise;
    const tx = db.transaction('users', 'readonly');
    const store = tx.objectStore('users');

    // Iterate through the store to find the user
    let cursor = await store.openCursor();
    while (cursor) {
      if (cursor.value.username === username) {
        return cursor.value;
      }
      cursor = await cursor.continue();
    }

    return null; // Return null if no user found
  } catch (error) {
    console.error(`Failed to find user with username "${username}":`, error);
    return null;
  }
};

// Update a user's data
export const updateUser = async (updatedUser) => {
  try {
    const db = await dbPromise;

    // Ensure the user exists before updating
    const existingUser = await findUserByUsername(updatedUser.username);
    if (!existingUser) {
      throw new Error(`User with username "${updatedUser.username}" does not exist.`);
    }

    await db.put('users', updatedUser);
    return { success: true };
  } catch (error) {
    console.error('Failed to update user:', error);
    return { success: false, error: error.message };
  }
};

// Delete a user by ID (optional utility)
export const deleteUserById = async (id) => {
  try {
    const db = await dbPromise;
    await db.delete('users', id);
    return { success: true };
  } catch (error) {
    console.error(`Failed to delete user with ID "${id}":`, error);
    return { success: false, error: error.message };
  }
};

// Fetch a user by ID
export const getUserById = async (id) => {
  try {
    const db = await dbPromise; // Ensure `dbPromise` is initialized for IndexedDB
    const user = await db.get('users', id);
    return user || null; // Return user if found, otherwise return null
  } catch (error) {
    console.error(`Error fetching user with ID ${id}:`, error);
    return null;
  }
};


// Get users by role
export const getUsersByRole = async (role) => {
  try {
    const db = await dbPromise;
    const users = await db.getAll('users'); // Fetch all users

    if (!users) {
      console.error('No users found in the database.');
      return []; // Return an empty array if no users exist
    }

    const filteredUsers = users.filter((user) => user.role === role)
    .map((user) => ({
      ...user,
      name: `${user.firstName} ${user.lastName}` // Add a combined name property
    })); // Filter by role
    console.log('Filtered Owners:', filteredUsers); // Debug log
    return filteredUsers;
  } catch (error) {
    console.error('Error fetching users by role:', error);
    return []; // Safely return an empty array if an error occurs
  }
};

