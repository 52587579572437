const menuConfig = [
  {
    title: 'Organization Menu', // Combined Menu Name
    path: '/organization',
    children: [
      { title: 'Users List', path: '/organization/user-list' }, // Moved Users List under Organization
      { title: 'Organization List', path: '/organization/organization-list' },
      { title: 'Company List', path: '/organization/company-list' }

    ]
  }
];

export default menuConfig;
