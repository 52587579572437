import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaSignOutAlt, FaGlobe } from 'react-icons/fa'; // Import icons from react-icons
import styles from './Layout.module.css';

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/');
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang); // Switch language
  };

  return (
    <header className={styles.header}>
      <h1 className={styles.title}>{t('header.title')}</h1>
      <div className={styles.headerActions}>
        {/* Language Dropdown */}
        <div className={styles.languageSelector}>
          <FaGlobe title={t('header.language')} className={styles.icon} />
          <select
            onChange={(e) => handleLanguageChange(e.target.value)}
            className={styles.languageDropdown}
          >
            <option value="en">English</option>
            <option value="ta">தமிழ்</option>
          </select>
        </div>
        {/* Logout Icon */}
        <button onClick={handleLogout} className={styles.iconButton} title={t('header.logout')}>
          <FaSignOutAlt className={styles.icon} />
        </button>
      </div>
    </header>
  );
};

export default Header;
