import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCompanyById, updateCompany } from '../../services/companyStorage';
import { getOrganizations } from '../../services/organizationStorage';
import styles from './NewCompanyPage.module.css';

const EditCompanyPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchCompany = async () => {
      const company = await getCompanyById(Number(id));
      if (company) {
        setFormData(company);
      } else {
        console.error('Company not found');
        navigate('/organization/company-list');
      }
    };

    const fetchOrganizations = async () => {
      const data = await getOrganizations();
      setOrganizations(data);
    };

    fetchCompany();
    fetchOrganizations();
  }, [id, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateCompany(formData);
    navigate('/organization/company-list');
  };

  if (!formData) return <p>Loading...</p>;

  return (
    <div className={styles.container}>
      <h2>Edit Company</h2>
      <form onSubmit={handleSubmit}>
        <label>Company Name</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          required
        />

        <label>Phone No</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          required
        />

        <label>Mail ID</label>
        <input
          type="email"
          name="mailId"
          value={formData.mailId}
          onChange={handleInputChange}
          required
        />

        <label>Organization</label>
        <select
          name="organizationId"
          value={formData.organizationId}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Organization</option>
          {organizations.map((org) => (
            <option key={org.id} value={org.id}>
              {org.organizationName}
            </option>
          ))}
        </select>

        <div className={styles.actions}>
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
          <button
            type="button"
            onClick={() => navigate('/organization/company-list')}
            className={styles.cancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCompanyPage;
