import { openDB } from 'idb';

const dbPromise = openDB('organization-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('organizations')) {
      db.createObjectStore('organizations', { keyPath: 'id', autoIncrement: true });
    }
  }
});

export const getOrganizations = async () => {
  const db = await dbPromise;
  return db.getAll('organizations');
};

export const addOrganization = async (organization) => {
  const db = await dbPromise;
  return db.add('organizations', organization);
};

export const deleteOrganization = async (id) => {
  const db = await dbPromise;
  return db.delete('organizations', id);
};

export const updateOrganizationStatus = async (id, status) => {
  const db = await dbPromise;
  const org = await db.get('organizations', id);
  org.status = status;
  return db.put('organizations', org);
};

export const getOrganizationById = async (id) => {
    const db = await dbPromise;
    return await db.get('organizations', id);
  };

  export const updateOrganization = async (organization) => {
    const db = await dbPromise;
    await db.put('organizations', organization);
  };
  