import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addOrganization } from '../../services/organizationStorage';
import { getUsersByRole } from '../../services/userStorage'; // Fetch users with 'owner' role
import styles from './NewOrganizationPage.module.css';

const NewOrganizationPage = () => {
  const [formData, setFormData] = useState({
    organizationName: '',
    companyName: '',
    ownerName: '',
    ownerId: '', // Store the owner's ID for dropdown selection
    phoneNumber: '',
    emailId: '',
    addresses: [{ type: 'Permanent Address', address: '' }], // Default address field
    deactivated: true
  });

  const [owners, setOwners] = useState([]); // List of owners
  const navigate = useNavigate();

  // Fetch users with 'owner' role on component mount
  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const ownerUsers = await getUsersByRole('owner'); // Ensure this function fetches only 'owner' users
        if (!ownerUsers.length) {
            console.warn('No owners found with the role "owner".');
          }
          console.info('owners:', ownerUsers);
        setOwners(ownerUsers);
      } catch (error) {
        console.error('Error fetching owners:', error);
        setOwners([]); // Ensure fallback to an empty array
      }
    };
    fetchOwners();
  }, []);
  
  // Handle input changes for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle owner selection
  const handleOwnerChange = (e) => {
    const selectedId = e.target.value;
    console.log('Selected ID:', selectedId);
    console.log('Owners List:', owners);
    //const selectedOwner = owners.find((owner) => owner.id === Number(selectedId));
    const selectedOwner = owners.find((owner) => String(owner.id) === selectedId);


    console.log('selectedOwner: ',selectedOwner);
    if (!selectedOwner) {
      console.error('Selected owner not found in the owners list.');
      return;
    }
  
    setFormData((prev) => ({
      ...prev,
      ownerName: `${selectedOwner.firstName} ${selectedOwner.lastName}`, 
      ownerId: selectedOwner.id,
      phoneNumber: selectedOwner.phoneNumber || '', // Handle undefined values
      emailId: selectedOwner.email || '' // Handle undefined values
    }));
  };
  
  

  // Handle address input changes
  const handleAddressChange = (index, key, value) => {
    const updatedAddresses = formData.addresses.map((address, i) =>
      i === index ? { ...address, [key]: value } : address
    );
    setFormData((prev) => ({ ...prev, addresses: updatedAddresses }));
  };

  // Add a new address field
  const addAddressField = () => {
    setFormData((prev) => ({
      ...prev,
      addresses: [...prev.addresses, { type: 'Communication Address', address: '' }]
    }));
  };

  // Remove an address field
  const removeAddressField = (index) => {
    setFormData((prev) => ({
      ...prev,
      addresses: prev.addresses.filter((_, i) => i !== index)
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    await addOrganization(formData);
    navigate('/organization/organization-list');
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Create New Organization</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label className={styles.label}>Organization Name</label>
        <input
          type="text"
          name="organizationName"
          value={formData.organizationName}
          onChange={handleInputChange}
          className={styles.input}
          required
        />

        <label className={styles.label}>Company Name</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          className={styles.input}
          required
        />

        <label className={styles.label}>Owner Name</label>
        <select
          name="ownerId" // Use ownerId for the dropdown value
          value={formData.ownerId} // Bind to ownerId in formData
          onChange={handleOwnerChange}
          className={styles.select}
          required
        >
          <option value="">Select Owner</option>
          {owners.map((owner) => (
            <option key={owner.id} value={owner.id}>
               {owner.name || 'Unnamed Owner'}
            </option>
          ))}
        </select>

        <label className={styles.label}>Owner Mobile No</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          className={styles.input}
          readOnly
        />

        <label className={styles.label}>Mail ID</label>
        <input
          type="email"
          name="emailId"
          value={formData.emailId}
          className={styles.input}
          readOnly
        />

        <label className={styles.label}>Addresses</label>
        {formData.addresses.map((address, index) => (
          <div key={index} className={styles.addressField}>
            <select
              value={address.type}
              onChange={(e) => handleAddressChange(index, 'type', e.target.value)}
              className={styles.select}
            >
              <option value="Permanent Address">Permanent Address</option>
              <option value="Communication Address">Communication Address</option>
            </select>
            <input
              type="text"
              value={address.address}
              onChange={(e) => handleAddressChange(index, 'address', e.target.value)}
              className={styles.input}
              placeholder="Enter Address"
              required
            />
            {formData.addresses.length > 1 && (
              <button
                type="button"
                onClick={() => removeAddressField(index)}
                className={styles.removeButton}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={addAddressField} className={styles.addButton}>
          + Add Address
        </button>

        <label className={styles.label}>
          <input
            type="checkbox"
            name="deactivated"
            checked={formData.deactivated}
            onChange={(e) => setFormData((prev) => ({ ...prev, deactivated: e.target.checked }))}
          />
          Deactivate
        </label>

        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
          <button type="button" onClick={() => navigate('/organization/organization-list')} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewOrganizationPage;
