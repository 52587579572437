import { openDB } from 'idb';

// Initialize IndexedDB
const dbPromise = openDB('app-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('companies')) {
      db.createObjectStore('companies', { keyPath: 'id', autoIncrement: true });
    }
  }
});

// Get all companies
export const getCompanies = async () => {
  const db = await dbPromise;
  return await db.getAll('companies');
};

// Add a new company
export const addCompany = async (company) => {
  const db = await dbPromise;
  await db.add('companies', company);
};

// Delete a company
export const deleteCompany = async (id) => {
  const db = await dbPromise;
  await db.delete('companies', id);
};

// Get a company by ID
export const getCompanyById = async (id) => {
  const db = await dbPromise;
  return await db.get('companies', id);
};

// Update a company
export const updateCompany = async (company) => {
  const db = await dbPromise;
  await db.put('companies', company);
};
