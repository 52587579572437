import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateUser } from '../../services/userStorage';
import styles from './ResetPassword.module.css';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the user from the location state
  const user = location.state?.user;

  if (!user) {
    navigate('/'); // Redirect to login if no user is provided
    return null;
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      // Update the user with the new password and reset the flag
      const updatedUser = { ...user, password: newPassword, mustResetPassword: false };
      await updateUser(updatedUser);

      setSuccessMessage('Password reset successfully.');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      console.error('Failed to reset password:', err);
      setError('Failed to reset password. Please try again.');
    }
  };

  const handleGoBackToLogin = () => {
    navigate('/');
  };

  return (
    <div className={styles.resetContainer}>
      <h2 className={styles.resetTitle}>Reset Password</h2>
      {error && <p className={styles.error}>{error}</p>}
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      <form onSubmit={handlePasswordReset} className={styles.resetForm}>
        <input
          type="password"
          placeholder="New Password"
          className={styles.inputField}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className={styles.inputField}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit" className={styles.submitButton}>
          Reset Password
        </button>
      </form>
      <button onClick={handleGoBackToLogin} className={styles.backButton}>
        Back to Login
      </button>
    </div>
  );
};

export default ResetPassword;
