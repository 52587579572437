import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import styles from './Layout.module.css';

const MainLayout = () => {
  return (
    <div className={styles.layoutContainer}>
      <Header />
      <div className={styles.mainContent}>
        <Sidebar />
        <div className={styles.contentArea}>
          <Outlet /> {/* Displays the active route's content */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
