import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrganizations } from '../../services/organizationStorage';
import { addCompany } from '../../services/companyStorage';
import styles from './NewCompanyPage.module.css';

const NewCompanyPage = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    mailId: '',
    organizationId: ''
  });
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      const data = await getOrganizations();
      setOrganizations(data);
    };
    fetchOrganizations();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addCompany(formData);
    navigate('/organization/company-list');
  };

  return (
    <div className={styles.container}>
      <h2>Create New Company</h2>
      <form onSubmit={handleSubmit}>
        <label>Company Name</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          required
        />

        <label>Phone No</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          required
        />

        <label>Mail ID</label>
        <input
          type="email"
          name="mailId"
          value={formData.mailId}
          onChange={handleInputChange}
          required
        />

        <label>Organization</label>
        <select
          name="organizationId"
          value={formData.organizationId}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Organization</option>
          {organizations.map((org) => (
            <option key={org.id} value={org.id}>
              {org.organizationName}
            </option>
          ))}
        </select>

        <div className={styles.actions}>
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
          <button
            type="button"
            onClick={() => navigate('/organization/company-list')}
            className={styles.cancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCompanyPage;
