import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainLayout from './components/Layout/MainLayout'; // Layout Component
import Login from './components/Auth/Login';
import UserListPage from './components/UserManagement/UserListPage';
import ResetPassword from './components/Auth/ResetPassword';
import CreateUserPage from './components/Admin/CreateUserPage';
import OwnerPage from './components/Owner/OwnerPage';
import TasksPage from './components/Tasks/TasksPage';
import LandingPage from './pages/LandingPage';
import AdminDashboard from './components/Admin/AdminDashboard';
import OrganizationListPage from './components/Organization/OrganizationListPage';
import NewOrganizationPage from './components/Organization/NewOrganizationPage';
import EditOrganizationPage from './components/Organization/EditOrganizationPage';
import CompanyListPage from './components/Organization/CompanyListPage';
import NewCompanyPage from './components/Organization/NewCompanyPage';
import EditCompanyPage from './components/Organization/EditCompanyPage';
import EditUserPage from './components/UserManagement/EditUserPage';

const AppRoutes = () => {
  const [users, setUsers] = useState([]);

  // Function to handle user creation
  const handleCreateUser = (user) => {
    setUsers((prevUsers) => [...prevUsers, user]);
  };

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes with Layout */}
        <Route path="/" element={<MainLayout />}>
          {/* Landing Page */}
          <Route path="landing" element={<LandingPage />} />

          {/* Organization Menu Routes */}
          <Route path="organization/user-list" element={<UserListPage users={users} />} />
          <Route path="organization/create-user" element={<CreateUserPage />} />


          {/* Existing Role-Based Routes */}
          <Route path="owner" element={<OwnerPage />} />
          <Route path="tasks" element={<TasksPage />} />

          {/* Admin Dashboard */}
          <Route path="admin/dashboard" element={<AdminDashboard />} />
          <Route path="user-management/edit-user/:id" element={<EditUserPage />} />


           {/* Organization menu */}
          <Route path="organization/organization-list" element={<OrganizationListPage />} />
          <Route path="organization/new-organization" element={<NewOrganizationPage />} />
          <Route path="organization/edit-organization/:id" element={<EditOrganizationPage />} />

           {/* Company menu */}
          <Route path="organization/company-list" element={<CompanyListPage />} />
          <Route path="organization/new-company" element={<NewCompanyPage />} />
          <Route path="organization/edit-company/:id" element={<EditCompanyPage />} />


        </Route>

        {/* Default Redirect for Unmatched Routes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
