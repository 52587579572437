import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getOrganizations,
  deleteOrganization,
  updateOrganizationStatus
} from '../../services/organizationStorage';
import styles from './OrganizationListPage.module.css';
import { useTranslation } from 'react-i18next';

const OrganizationListPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOrganizations();
      setOrganizations(data);
    };
    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((recordId) => recordId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    if (selectedRecords.length > 0) {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete ${selectedRecords.length} record(s)?`
      );
      if (confirmDelete) {
        await Promise.all(selectedRecords.map((id) => deleteOrganization(id)));
        setOrganizations(await getOrganizations());
        setSelectedRecords([]);
      }
    }
  };

  const handleStatusUpdate = async (id, status) => {
    await updateOrganizationStatus(id, !status);
    setOrganizations(await getOrganizations());
  };

  const handleEdit = () => {
    if (selectedRecords.length === 1) {
      navigate(`/organization/edit-organization/${selectedRecords[0]}`);
    }
  };

  const filteredOrganizations = organizations.filter((org) =>
    org.organizationName.toLowerCase().includes(searchQuery) ||
    org.phoneNumber.includes(searchQuery) ||
    org.companyName.toLowerCase().includes(searchQuery)
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <input
          type="text"
          placeholder="Search by Name, Phone, or Company"
          value={searchQuery}
          onChange={handleSearch}
          className={styles.searchBox}
        />

      </div>
      <div className={styles.actionIcons}>
      <button
          onClick={() => navigate('/organization/new-organization')}
          className={styles.newButton}
        >
          {t('datatable.new')}
        </button>
        <button
          onClick={handleEdit}
          className={styles.iconButton}
          disabled={selectedRecords.length !== 1}
        >
          ✏️ {t('datatable.edit')}
        </button>
        <button
          onClick={handleDelete}
          className={styles.iconButton}
          disabled={selectedRecords.length === 0}
        >
          🗑️ {t('datatable.delete')}
        </button>
      </div>
      <table className={styles.organizationTable}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) =>
                  setSelectedRecords(
                    e.target.checked ? organizations.map((org) => org.id) : []
                  )
                }
                checked={selectedRecords.length === organizations.length}
              />
            </th>
            <th>{t('datatable.orgName')}</th>
            <th>{t('datatable.ownerName')}</th>
            <th>{t('datatable.phoneNumber')}</th>
            <th>{t('datatable.status')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrganizations.map((org) => (
            <tr key={org.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRecords.includes(org.id)}
                  onChange={() => handleCheckboxChange(org.id)}
                />
              </td>
              <td>{org.organizationName}</td>
              <td>{org.ownerName}</td>
              <td>{org.phoneNumber}</td>
              <td
                onClick={() => handleStatusUpdate(org.id, org.deactivated)}
                style={{
                  cursor: 'pointer',
                  color: org.deactivated ? 'red' : 'green',
                  fontWeight: 'bold'
                }}
              >
                {org.deactivated ? 'De-Active' : 'Active'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
};

export default OrganizationListPage;
