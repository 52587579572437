import React from 'react';
import styles from './LandingPage.module.css';

const LandingPage = () => {
  return (
    <div className={styles.landingContainer}>
      <h2 className={styles.welcomeMessage}>Welcome, Admin!</h2>
      <p className={styles.instructions}>Use the menu on the left to navigate through the application.</p>
    </div>
  );
};

export default LandingPage;
