import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getUsers,
  getUserById,
  updateUser,
} from '../../services/userStorage';
import { getOrganizations } from '../../services/organizationStorage';
import { getCompanies } from '../../services/companyStorage';
import styles from './EditUserPage.module.css';

const EditUserPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [fileUploads, setFileUploads] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUserById(Number(id));
      const orgs = await getOrganizations();
      const comps = await getCompanies();

      setFormData(user);
      setOrganizations(orgs);
      setCompanies(comps);

      if (orgs.length === 0 || comps.length === 0) {
        setWarningMessage(
          'Please create an Organization and Company. Staff needs to be assigned to a Company.'
        );
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCompanyMultiSelect = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setFormData((prev) => ({
      ...prev,
      companies: selectedOptions,
    }));
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setFileUploads((prev) => [...prev, ...files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateUser({
      ...formData,
      documents: fileUploads, // Save uploaded files
    });
    navigate('/user-management/user-list');
  };

  if (!formData) return <p>Loading...</p>;

  return (
    <div className={styles.container}>
      <h2>Edit User</h2>
      {warningMessage && <p className={styles.warning}>{warningMessage}</p>}
      <form onSubmit={handleSubmit}>
        <label className={styles.label}>First Name</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName || ''}
          onChange={handleInputChange}
          className={styles.input}
          required
        />

        <label className={styles.label}>Last Name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName || ''}
          onChange={handleInputChange}
          className={styles.input}
          required
        />

        <label className={styles.label}>Role</label>
        <input
          type="text"
          name="role"
          value={formData.role || ''}
          onChange={handleInputChange}
          className={styles.input}
          disabled
        />

        {formData.role === 'staff' && (
          <>
            <label className={styles.label}>Organization</label>
            <select
              name="organizationId"
              value={formData.organizationId || ''}
              onChange={handleInputChange}
              className={styles.select}
              required
            >
              <option value="">Select Organization</option>
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.organizationName}
                </option>
              ))}
            </select>

            <label className={styles.label}>Companies</label>
            <select
              name="companies"
              multiple
              value={formData.companies || []}
              onChange={handleCompanyMultiSelect}
              className={styles.select}
            >
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.companyName}
                </option>
              ))}
            </select>

            <label className={styles.label}>
              <input
                type="checkbox"
                name="readOnly"
                checked={formData.readOnly || false}
                onChange={handleInputChange}
                className={styles.checkbox}
              />
              READ ONLY
            </label>
          </>
        )}

        <label className={styles.label}>Upload Documents</label>
        <input
          type="file"
          multiple
          onChange={handleFileUpload}
          className={styles.fileInput}
        />

        <div className={styles.actions}>
          <button 
            type="submit" 
            onClick={() => navigate('/organization/user-list')}
            className={styles.saveButton}>
            Save
          </button>
          <button
            type="button"
            onClick={() => navigate('/organization/user-list')}
            className={styles.cancelButton}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUserPage;
