import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers, deleteUserById } from '../../services/userStorage';
import Pagination from '../Common/Pagination';
import styles from './UserListPage.module.css';
import { useTranslation } from 'react-i18next';

const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setUsers(data);
    };
    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async () => {
    if (selectedRecords.length > 0) {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete ${selectedRecords.length} record(s)?`
      );
      if (confirmDelete) {
        await Promise.all(selectedRecords.map((id) => deleteUserById(id)));
        setUsers(await getUsers());
        setSelectedRecords([]);
      }
    }
  };

  const handleEdit = () => {
    if (selectedRecords.length === 1) {
      navigate(`/user-management/edit-user/${selectedRecords[0]}`);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      (user.name?.toLowerCase() || '').includes(searchQuery) ||
      (user.username?.toLowerCase() || '').includes(searchQuery) ||
      (user.city?.toLowerCase() || '').includes(searchQuery) ||
      (user.phoneNumber || '').includes(searchQuery) ||
      (user.role?.toLowerCase() || '').includes(searchQuery)
  );

  const totalPages = Math.ceil(filteredUsers.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const paginatedUsers = filteredUsers.slice(startIndex, startIndex + recordsPerPage);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <input
          type="text"
          placeholder={t('datatable.searchPlaceholder')} // Dynamic search placeholder
          value={searchQuery}
          onChange={handleSearch}
          className={styles.searchBox}
        />
      </div>
      <div className={styles.actionIcons}>
        <button
          onClick={() => navigate('/organization/create-user')}
          className={styles.newButton}
        >{t('datatable.createUser')} {/* Translated "Create User" */}</button>
        <button
          onClick={handleEdit}
          className={styles.iconButton}
          disabled={selectedRecords.length !== 1}
        >
        ✏️ {t('datatable.edit')} {/* Translated "Edit" */}
        </button>
        <button
          onClick={handleDelete}
          className={styles.iconButton}
          disabled={selectedRecords.length === 0}
        >
          🗑️ {t('datatable.delete')} {/* Translated "Delete" */}
        </button>
      </div>
      <table className={styles.userTable}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) =>
                  setSelectedRecords(
                    e.target.checked ? users.map((user) => user.id) : []
                  )
                }
                checked={selectedRecords.length === users.length}
              />
            </th>
            <th>{t('datatable.name')}</th>
            <th>{t('datatable.username')}</th>
            <th>{t('datatable.city')}</th>
            <th>{t('datatable.phoneNumber')}</th>
            <th>{t('datatable.role')}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map((user) => (
            <tr key={user.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRecords.includes(user.id)}
                  onChange={() =>
                    setSelectedRecords((prevSelected) =>
                      prevSelected.includes(user.id)
                        ? prevSelected.filter((recordId) => recordId !== user.id)
                        : [...prevSelected, user.id]
                    )
                  }
                />
              </td>
              <td>{user.name || 'N/A'}</td>
              <td>{user.username || 'N/A'}</td>
              <td>{user.city || 'N/A'}</td>
              <td>{user.phoneNumber || 'N/A'}</td>
              <td>{user.role || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default UserListPage;
