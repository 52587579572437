import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { findUserByUsername } from '../../services/userStorage';
import styles from './Login.module.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    const trimmedUsername = username.trim().toLowerCase();
    const trimmedPassword = password.trim();

    const user = await findUserByUsername(trimmedUsername);

    console.log('Retrieved user:', user);
    console.log('Entered password:', trimmedPassword);

    if (!user) {
      setError('Invalid username or password.');
      console.error('User not found.');
      return;
    }

    if (user.password !== trimmedPassword) {
      setError('Invalid username or password.');
      console.error('Password mismatch.');
      return;
    }

    if (user.mustResetPassword) {
      navigate('/reset-password', { state: { user } });
    } else {
      if (user.role === 'admin') navigate('/admin/dashboard'); // Updated redirection
      else if (user.role === 'owner') navigate('/owner');
      else navigate('/tasks');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <h2 className={styles.loginTitle}>User Login</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          className={styles.inputField}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className={styles.inputField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className={styles.loginButton}>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
