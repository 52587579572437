import React from 'react';
import styles from './Pagination.module.css';
import { useTranslation } from 'react-i18next';


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { t } = useTranslation();
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className={styles.pagination}>
      <button 
        onClick={handlePrevious} 
        disabled={currentPage === 1} 
        className={styles.button}
      >
         {t('pagination.previous')}
      </button>
      <span className={styles.pageInfo}>
        Page {currentPage} of {totalPages}
      </span>
      <button 
        onClick={handleNext} 
        disabled={currentPage === totalPages} 
        className={styles.button}
      >
        {t('pagination.next')}
      </button>
    </div>
  );
};

export default Pagination;
