import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ta'],
    fallbackLng: 'en',
    debug: true,
    returnObjects: false, // Explicitly disable (default is false)
    missingKeyHandler: (lng, ns, key, res) => {
        console.error(`Missing key: ${key} in namespace: ${ns}, language: ${lng}`);
      },
    ns: ['translation', 'common'], // Add namespaces
    interpolation: {
      escapeValue: false, // React handles escaping
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to translation files
    },
    cache: {
        enabled: false, // Disable caching during debugging
      },
  });

export default i18n;
