// src/components/Admin/AdminDashboard.js

import React from 'react';

const AdminDashboard = () => {
  return (
    <div>
      <h2>Welcome, Admin!</h2>
      <p>Use the menu on the left to navigate.</p>
    </div>
  );
};

export default AdminDashboard;
