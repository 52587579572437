import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCompanies, deleteCompany } from '../../services/companyStorage';
import { getOrganizations } from '../../services/organizationStorage';
import styles from './CompanyListPage.module.css';
import { useTranslation } from 'react-i18next';

const CompanyListPage = () => {
  const [companies, setCompanies] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const navigate = useNavigate();
  const normalizeId = (id) => String(id);
  const { t } = useTranslation('common');


  useEffect(() => {
    const fetchData = async () => {
      const companyData = await getCompanies();
      const organizationData = await getOrganizations();

      console.log('Company Data:', companyData); // Log companies
      console.log('Organization Data:', organizationData); // Log organizations

      // Map organization names to companies
      const enrichedCompanies = companyData.map((company) => ({
        ...company,
        organizationName: organizationData.find(
          (org) => normalizeId(org.id) === normalizeId(company.organizationId)
        )?.organizationName || 'Unknown'
      }));

      setCompanies(enrichedCompanies);
      setOrganizations(organizationData);
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((recordId) => recordId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    if (selectedRecords.length > 0) {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete ${selectedRecords.length} record(s)?`
      );
      if (confirmDelete) {
        await Promise.all(selectedRecords.map((id) => deleteCompany(id)));
        setCompanies(await getCompanies());
        setSelectedRecords([]);
      }
    }
  };

  const handleEdit = () => {
    if (selectedRecords.length === 1) {
      navigate(`/organization/edit-company/${selectedRecords[0]}`);
    }
  };

  const filteredCompanies = companies.filter((company) =>
    (company.companyName?.toLowerCase() || '').includes(searchQuery) ||
    (company.phoneNumber || '').includes(searchQuery) ||
    (company.mailId?.toLowerCase() || '').includes(searchQuery) ||
    (company.organizationName?.toLowerCase() || '').includes(searchQuery)
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <input
          type="text"
          placeholder="Search by Name, Phone, Mail, or Organization"
          value={searchQuery}
          onChange={handleSearch}
          className={styles.searchBox}
        />

      </div>
      <div className={styles.actionIcons}>
      <button
          onClick={() => navigate('/organization/new-company')}
          className={styles.newButton}
        >
          {t('datatable.new')}
        </button>
        <button
          onClick={handleEdit}
          className={styles.iconButton}
          disabled={selectedRecords.length !== 1}
        >
          ✏️ {t('datatable.edit')}
        </button>
        <button
          onClick={handleDelete}
          className={styles.iconButton}
          disabled={selectedRecords.length === 0}
        >
          🗑️ {t('datatable.delete')}
        </button>
      </div>
      <table className={styles.companyTable}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) =>
                  setSelectedRecords(
                    e.target.checked ? companies.map((company) => company.id) : []
                  )
                }
                checked={selectedRecords.length === companies.length}
              />
            </th>
            <th>{t('datatable.companyName')}</th>
            <th>{t('datatable.phoneNumber')}</th>
            <th>{t('datatable.emailId')}</th>
            <th>{t('datatable.orgName')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanies.map((company) => (
            <tr key={company.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRecords.includes(company.id)}
                  onChange={() => handleCheckboxChange(company.id)}
                />
              </td>
              <td>{company.companyName || 'N/A'}</td>
              <td>{company.phoneNumber || 'N/A'}</td>
              <td>{company.mailId || 'N/A'}</td>
              <td>{company.organizationName || 'Unknown'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyListPage;
