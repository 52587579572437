import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addUser } from '../../services/userStorage';
import { getOrganizations } from '../../services/organizationStorage';
import { getCompanies } from '../../services/companyStorage';
import styles from './CreateUserPage.module.css';

const CreateUserPage = () => {
  const [userForm, setUserForm] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    city: '',
    email: '',
    dob: '',
    role: 'staff',
    organizationId: '',
    companies: [],
    readOnly: false,
    username: '',
    password: 'user@123',
    mustResetPassword: true,
    documents: [],
  });

  const [organizations, setOrganizations] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [error, setError] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDropdownData = async () => {
      const orgs = await getOrganizations();
      const comps = await getCompanies();

      setOrganizations(orgs);
      setAllCompanies(comps);

      if (orgs.length === 0 || comps.length === 0) {
        setWarningMessage(
          'Please create an organization and company. Staff needs to be assigned to a company.'
        );
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (userForm.organizationId) {
      const filtered = allCompanies.filter(
        (company) => String(company.organizationId) === String(userForm.organizationId)
      );
      setFilteredCompanies(filtered);
    } else {
      setFilteredCompanies([]);
    }
  }, [userForm.organizationId, allCompanies]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserForm((prevForm) => ({
      ...prevForm,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'firstName' || name === 'lastName' || name === 'phoneNumber') {
      updateUsername(value, name);
    }
  };

  const handleCompanyMultiSelect = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setUserForm((prevForm) => ({ ...prevForm, companies: selectedOptions }));
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setUserForm((prevForm) => ({ ...prevForm, documents: [...prevForm.documents, ...files] }));
  };

  const updateUsername = (value, fieldName) => {
    const { firstName, lastName, phoneNumber } = { ...userForm, [fieldName]: value };
    if (firstName && lastName && phoneNumber.length >= 2) {
      const generatedUsername = `${firstName}${lastName.slice(0, 2)}${phoneNumber[0]}${phoneNumber.slice(-1)}`;
      setUserForm((prevForm) => ({ ...prevForm, username: generatedUsername.toLowerCase() }));
    }
  };

  const validateForm = () => {
    if (!/^\d{10}$/.test(userForm.phoneNumber)) {
      setError('Phone number must be 10 digits.');
      return false;
    }
    if (userForm.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userForm.email)) {
      setError('Invalid email format.');
      return false;
    }
    if (userForm.role === 'staff' && (!userForm.organizationId || userForm.companies.length === 0)) {
      setError('Staff must be assigned to an organization and at least one company.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!validateForm()) {
      return;
    }

    try {
      await addUser(userForm);
      setSuccessMessage('User created successfully!');
      setUserForm({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        city: '',
        email: '',
        dob: '',
        role: 'staff',
        organizationId: '',
        companies: [],
        readOnly: false,
        username: '',
        password: 'user@123',
        mustResetPassword: true,
        documents: [],
      });
    } catch (err) {
      setError('Failed to create user. Please try again.');
    }
  };

  return (
    <div className={styles.container}>
      <h2>Create New User</h2>
      {warningMessage && <p className={styles.warning}>{warningMessage}</p>}
      {error && <p className={styles.error}>{error}</p>}
      {successMessage && (
        <div className={styles.successMessage}>
          <p>{successMessage}</p>
          <button
            onClick={() => navigate('/organization/user-list')}
            className={styles.navigationButton}
          >
            Go to User List
          </button>
          <button
            onClick={() => setSuccessMessage('')}
            className={styles.navigationButton}
          >
            Create Another User
          </button>
        </div>
      )}
      {!successMessage && (
        <form onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label>First Name</label>
            <input type="text" name="firstName" value={userForm.firstName} onChange={handleInputChange} required />
          </div>
          <div className={styles.formControl}>
            <label>Last Name</label>
            <input type="text" name="lastName" value={userForm.lastName} onChange={handleInputChange} required />
          </div>
          <div className={styles.formControl}>
            <label>Phone Number</label>
            <input type="text" name="phoneNumber" value={userForm.phoneNumber} onChange={handleInputChange} required />
          </div>
          <div className={styles.formControl}>
            <label>City</label>
            <input type="text" name="city" value={userForm.city} onChange={handleInputChange} />
          </div>
          <div className={styles.formControl}>
            <label>Email Address</label>
            <input type="email" name="email" value={userForm.email} onChange={handleInputChange} />
          </div>
          <div className={styles.formControl}>
            <label>Date of Birth</label>
            <input type="date" name="dob" value={userForm.dob} onChange={handleInputChange} />
          </div>
          <div className={styles.formControl}>
            <label>Role</label>
            <select name="role" value={userForm.role} onChange={handleInputChange}>
              <option value="admin">Admin</option>
              <option value="owner">Owner</option>
              <option value="staff">Staff</option>
            </select>
          </div>
          {userForm.role === 'staff' && (
            <>
              <div className={styles.formControl}>
                <label>Organization</label>
                <select name="organizationId" value={userForm.organizationId} onChange={handleInputChange} required>
                  <option value="">Select Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.organizationName}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formControl}>
                <label>Companies</label>
                <select name="companies" multiple value={userForm.companies} onChange={handleCompanyMultiSelect}>
                  {filteredCompanies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formControl}>
                <label>
                  <input type="checkbox" name="readOnly" checked={userForm.readOnly} onChange={handleInputChange} />
                  READ ONLY
                </label>
              </div>
            </>
          )}
          <div className={styles.formControl}>
            <label>Upload Documents</label>
            <input type="file" multiple onChange={handleFileUpload} />
          </div>
          <div className={styles.actions}>
            <button type="submit" className={styles.submitButton}>Create User</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateUserPage;
